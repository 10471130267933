export default {
    filters: {
        mediaSize(item) {
            if(!item || !item.size) {
                return '---'
            }

            return Math.ceil(item.size / 1000) + ' KB'
        }
    }
}
