<template>
    <div
        v-if="item"
        class="media-details"
    >
        <v-img
            v-if="hasCover"
            :src="coverSrc"
            width="100%"
            max-height="165"
        />
        <v-list dense class="pa-2 mt-2">
            <v-list-item>
                <v-list-item-icon>
                    <media-icon :item="item"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.size">{{ item | mediaSize }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{ item | mediaTypeLabel }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="ml-12 mr-4 my-2"/>
            <v-list-item>
                <v-list-item-icon>
                    <w-icon value="USER"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>{{ $trans('Owner') }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.author | memberFullName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="ml-12 mr-4 my-2"/>
            <v-list-item>
                <v-list-item-icon>
                    <w-icon value="SHARE"/>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $trans('Available in') }}:</v-list-item-title>
                    <router-link
                        v-for="resource in computedResources"
                        :key="resource.uuid"
                        :to="resource.to"
                        class="text-sm text-uppercase text-truncate d-block"
                        style="padding: 2px 0"
                    >
                        {{ resource.title }}
                    </router-link>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import EventEmitter from '@/plugins/EventEmitter'
import { isImage } from '@apps/media/tools'
import MediaIcon from '@apps/media/components/MediaIcon'
import mediaSizeFilterMixin from '@apps/media/mixins/mediaSizeFilterMixin'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import mediaTypeLabelFilterMixin from '@apps/media/mixins/mediaTypeLabelFilterMixin'

export default {
    name: 'MediaDetails',
    mixins: [
        mediaSizeFilterMixin,
        hasMemberFullNameFilterMixin,
        mediaTypeLabelFilterMixin
    ],
    components: { MediaIcon },
    props: {
        item: {
            type: Object,
            default: null
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasCover() {
            if(!this.item) {
                return false
            }

            return isImage(this.item.src) ||
                (this.item.options && this.item.options.cover)
        },
        coverSrc() {
            return this.item.options.cover || this.item.url
        },
        computedResources() {
            if(!this.item && !this.item.resources) {
                return []
            }

            const resources = []

            this.item.resources.forEach(o => {
                const resource = Object.assign({to: ''}, o)

                EventEmitter.emit('media:init:resource', resource)

                resources.push(resource)
            })

            return resources
        }
    },
    data() {
        return {
            isDialog: false
        }
    }
}
</script>

<style lang=scss>

</style>
